const theme = {
   
    cmPlum: '#582C83',
    cmGold: '#F1c400',
    nobelGray: '#9d9d9d',
    darkGray: '#5a5a65',
    upperCase: 'uppercase',
    // display: ${props => props.auth ? 'block' : 'none'},
 }
 
 export default theme