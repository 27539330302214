import React from 'react'
import ReactDOM from 'react-dom'
// import App from './App'
import { ThemeProvider } from 'styled-components'
import { Suspense, lazy } from 'react';
import theme from './styles/theme'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import "@fortawesome/fontawesome-free/css/all.min.css"
import "bootstrap-css-only/css/bootstrap.min.css"
import "mdbreact/dist/css/mdb.css"

const App = lazy(() => import('./App'));

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <Suspense fallback={<div>Loading...</div>}>
        <App />
      </Suspense>
    </ThemeProvider>
    , document.getElementById('root'))
